<template>
  <h1 class="screen_out">디케이테크인</h1>
  <div class="wrap_error">
    <h2 class="screen_out">ERROR</h2>
    <div>
      <h3 class="txt_error">{{ matchedErrorData.title }}</h3>
    </div>
    <div class="wrap_btn">
      <button
        type="button"
        class="btn_error"
        @click="$router.back()"
      >
        이전 화면으로 이동
      </button>
      <LinkBasic
        :text="'홈 화면으로 이동'"
        :link="'/'"
        :isBlank="false"
        class="btn_error"
      />
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  error: {
    type: Object,
    default() {
      return {};
    },
  },
});

const errorData = {
  404: {
    title: '페이지를 찾을 수 없습니다.',
  },
  500: {
    title: '서비스가 일시적으로 중단되었습니다.',
  },
};

const matchedErrorData = computed(() => {
  return errorData[props.error.statusCode] || errorData[500];
});

onMounted(() => {});
</script>
<style scoped lang="scss">
.wrap_error {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.txt_error {
  padding-top: 56px;
  margin-bottom: 44px;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  @include backgroundImage(
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='49' height='48' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M44.5 24c0-11.046-8.954-20-20-20s-20 8.954-20 20 8.954 20 20 20 20-8.954 20-20zm-37 0c0-9.389 7.611-17 17-17s17 7.611 17 17-7.611 17-17 17-17-7.611-17-17zm14.858 2.395a1.5 1.5 0 0 0-2.761-1.172 3.501 3.501 0 0 1-3.334 2.132l-.145.002a1.5 1.5 0 0 0 .05 2.996 6.501 6.501 0 0 0 6.19-3.958zm5.77-1.967a1.5 1.5 0 0 0-.795 1.967 6.501 6.501 0 0 0 6.191 3.958 1.5 1.5 0 0 0 .05-2.996l-.145-.002a3.501 3.501 0 0 1-3.335-2.132 1.5 1.5 0 0 0-1.966-.795zM23 33a1.5 1.5 0 0 0 0 3h4a1.5 1.5 0 0 0 0-3h-4z' fill='%23B3B3B3'/%3E%3C/svg%3E",
    48px 48px,
    top center
  );
}
.wrap_btn {
  display: flex;
  justify-content: center;
  margin: 0 -4px; //버튼사이 마진처리
}
.btn_error {
  display: inline-block;
  min-width: 160px;
  height: 44px;
  margin: 0 4px;
  font-size: 15px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
  border-radius: 4px;
  background-color: #4c4c4c;
  color: #fff;
}
@media screen and (max-width: 1023px) {
  .wrap_error {
    width: 100%;
    left: 0;
    transform: translate(0, -50%);
  }
  .txt_error {
    padding-top: 64px;
    font-size: 22px;
    margin-bottom: 40px;
  }
  .wrap_btn {
    display: block;
    max-width: 328px;
    margin: 0 auto;
  }
  .btn_error {
    width: 100%;
    & + .btn_error {
      margin-top: 8px;
    }
  }
}
@media screen and (max-width: 340px) {
  .wrap_btn {
    max-width: 250px;
  }
}
</style>
