export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1 maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"},{"name":"google-site-verification","content":"WqTJBLDGwipXlRuYDS0k_H7lhAECVewkkaM1BfaKW40"},{"name":"naver-site-verification","content":"6937f72ed6ec95ded59e68f69f3f7387c5a72330"},{"name":"title","content":"dk techin (디케이테크인)"},{"name":"description","content":"기술로 세상을 연결하는 IT서비스 전문기업"},{"property":"og:type","content":"website"},{"property":"og:url","content":"https://dktechin.com/"},{"property":"og:title","content":"dk techin (디케이테크인)"},{"property":"og:description","content":"기술로 세상을 연결하는 IT서비스 전문기업"},{"property":"og:image","content":"https://t1.kakaocdn.net/dkt_corp/service/og_img.png"},{"name":"twitter:image","content":"https://t1.kakaocdn.net/dkt_corp/service/og_img.png"}],"link":[{"rel":"icon","type":"image/svg","href":"/ico_favicon.png","sizes":"16x16"},{"rel":"icon","type":"image/svg","href":"/ico_favicon.png","sizes":"32x32"}],"style":[],"script":[{"src":"https://t1.kakaocdn.net/kakao_js_sdk/2.7.3/kakao.min.js"},{"src":"https://dapi.kakao.com/v2/maps/sdk.js?appkey=f147d492c07096ac05fb602dde0bdb85"},{"src":"https://t1.daumcdn.net/tiara/js/v1/tiara.min.js"},{"src":"https://t1.kakaocdn.net/dkt_corp/service/lottiePlayer.js"}],"noscript":[],"title":"dk techin (디케이테크인)","htmlAttrs":{"lang":"ko"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false