import revive_payload_client_I51L7vISWn from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@8.57.1_ioredi_6ac426ee34a7b9db0c568ce4fc0eecd5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_HH9oHOTnHa from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@8.57.1_ioredi_6ac426ee34a7b9db0c568ce4fc0eecd5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ybnKt68Xzt from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@8.57.1_ioredi_6ac426ee34a7b9db0c568ce4fc0eecd5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_82jBQ4WSo3 from "/app/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.28.1_vite@5.4.11_@types+node@22.10.2_sas_a99cc80787f2a22c28e470dc7cf3c021/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_2vyp0Jutbi from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@8.57.1_ioredi_6ac426ee34a7b9db0c568ce4fc0eecd5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_xHkt2C5TcA from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@8.57.1_ioredi_6ac426ee34a7b9db0c568ce4fc0eecd5/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_BAQNBmOdBw from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@8.57.1_ioredi_6ac426ee34a7b9db0c568ce4fc0eecd5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_8d6hbVo6Fn from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@8.57.1_ioredi_6ac426ee34a7b9db0c568ce4fc0eecd5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_NjftaKmj9i from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.28.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_lTAuHgR57U from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@8.57.1_ioredi_6ac426ee34a7b9db0c568ce4fc0eecd5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import kakaoShare_client_qlwYiUyLod from "/app/plugins/kakaoShare.client.js";
import tiara_client_R50QKhV6BT from "/app/plugins/tiara.client.js";
export default [
  revive_payload_client_I51L7vISWn,
  unhead_HH9oHOTnHa,
  router_ybnKt68Xzt,
  _0_siteConfig_82jBQ4WSo3,
  payload_client_2vyp0Jutbi,
  navigation_repaint_client_xHkt2C5TcA,
  check_outdated_build_client_BAQNBmOdBw,
  chunk_reload_client_8d6hbVo6Fn,
  plugin_vue3_NjftaKmj9i,
  components_plugin_KR1HBZs4kY,
  prefetch_client_lTAuHgR57U,
  plugin_wy0B721ODc,
  kakaoShare_client_qlwYiUyLod,
  tiara_client_R50QKhV6BT
]