const { TiaraTracker } = window;

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();
  const environment = runtimeConfig.public.NUXT_ENV_PRO;
  const deployment = environment === 'production' ? 'production' : 'dev';
  const config = {
    svcDomain: 'dktechin.com',
    deployment,
  };

  TiaraTracker.getInstance().init(config);
  TiaraTracker.getInstance().setAutoClick(true);
});
