import { default as _91id_93QgRvL19lFCMeta } from "/app/pages/case/[id].vue?macro=true";
import { default as indexVwQk6swCFBMeta } from "/app/pages/case/index.vue?macro=true";
import { default as indexq9vUxx6y6UMeta } from "/app/pages/download/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91id_93vbmxMTSEv2Meta } from "/app/pages/inquiry/[id].vue?macro=true";
import { default as faqxAfuJ9QEbMMeta } from "/app/pages/inquiry/faq.vue?macro=true";
import { default as indexbDXtdbt5iBMeta } from "/app/pages/inquiry/index.vue?macro=true";
import { default as searchlwHB1aBAAMMeta } from "/app/pages/inquiry/search.vue?macro=true";
import { default as indexb86PPmyZEvMeta } from "/app/pages/introduce/index.vue?macro=true";
import { default as _91id_93FIXkK569FdMeta } from "/app/pages/news/[id].vue?macro=true";
import { default as indexoxV4GRc61hMeta } from "/app/pages/news/index.vue?macro=true";
import { default as indexP0q8KYV1WMMeta } from "/app/pages/search/index.vue?macro=true";
import { default as chatbotnowsCZnDljywMMeta } from "/app/pages/service/chatbotnow.vue?macro=true";
import { default as dxMc6nxGLwhzMeta } from "/app/pages/service/dx.vue?macro=true";
import { default as itoOuxWh0UKOqMeta } from "/app/pages/service/ito.vue?macro=true";
import { default as kakaohomeaKgF5Ypo2GMeta } from "/app/pages/service/kakaohome.vue?macro=true";
import { default as kakaoi9e34NKH472Meta } from "/app/pages/service/kakaoi.vue?macro=true";
import { default as kakaoiauto5XmwOQ7zdAMeta } from "/app/pages/service/kakaoiauto.vue?macro=true";
import { default as kakaoiconnectalwaysH9DwnuIVrmMeta } from "/app/pages/service/kakaoiconnectalways.vue?macro=true";
import { default as kakaoiconnectcenter6lMsGlftwDMeta } from "/app/pages/service/kakaoiconnectcenter.vue?macro=true";
import { default as kakaoiconnectmsgb5ltjv0KSuMeta } from "/app/pages/service/kakaoiconnectmsg.vue?macro=true";
import { default as kakaoiconnecttalkfSl26sjFJKMeta } from "/app/pages/service/kakaoiconnecttalk.vue?macro=true";
import { default as kakaowork1b5ZxakyUHMeta } from "/app/pages/service/kakaowork.vue?macro=true";
import { default as sapBHxL3Qozn1Meta } from "/app/pages/service/sap.vue?macro=true";
import { default as smartconstruction7IXa3tkzq5Meta } from "/app/pages/service/smartconstruction.vue?macro=true";
import { default as smartminwonYgXCqX1bYsMeta } from "/app/pages/service/smartminwon.vue?macro=true";
import { default as indexUs9kSmeyUIMeta } from "/app/pages/service/uiux/index.vue?macro=true";
import { default as portfolioSpMHn7OyCLMeta } from "/app/pages/service/uiux/portfolio.vue?macro=true";
import { default as waytocomeQqFUR5nle8Meta } from "/app/pages/waytocome.vue?macro=true";
export default [
  {
    name: "case-id",
    path: "/case/:id()",
    component: () => import("/app/pages/case/[id].vue")
  },
  {
    name: "case",
    path: "/case",
    component: () => import("/app/pages/case/index.vue")
  },
  {
    name: "download",
    path: "/download",
    component: () => import("/app/pages/download/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "inquiry-id",
    path: "/inquiry/:id()",
    component: () => import("/app/pages/inquiry/[id].vue")
  },
  {
    name: "inquiry-faq",
    path: "/inquiry/faq",
    component: () => import("/app/pages/inquiry/faq.vue")
  },
  {
    name: "inquiry",
    path: "/inquiry",
    component: () => import("/app/pages/inquiry/index.vue")
  },
  {
    name: "inquiry-search",
    path: "/inquiry/search",
    component: () => import("/app/pages/inquiry/search.vue")
  },
  {
    name: "introduce",
    path: "/introduce",
    component: () => import("/app/pages/introduce/index.vue")
  },
  {
    name: "news-id",
    path: "/news/:id()",
    component: () => import("/app/pages/news/[id].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/app/pages/news/index.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/app/pages/search/index.vue")
  },
  {
    name: "service-chatbotnow",
    path: "/service/chatbotnow",
    component: () => import("/app/pages/service/chatbotnow.vue")
  },
  {
    name: "service-dx",
    path: "/service/dx",
    component: () => import("/app/pages/service/dx.vue")
  },
  {
    name: "service-ito",
    path: "/service/ito",
    component: () => import("/app/pages/service/ito.vue")
  },
  {
    name: "service-kakaohome",
    path: "/service/kakaohome",
    component: () => import("/app/pages/service/kakaohome.vue")
  },
  {
    name: "service-kakaoi",
    path: "/service/kakaoi",
    component: () => import("/app/pages/service/kakaoi.vue")
  },
  {
    name: "service-kakaoiauto",
    path: "/service/kakaoiauto",
    component: () => import("/app/pages/service/kakaoiauto.vue")
  },
  {
    name: "service-kakaoiconnectalways",
    path: "/service/kakaoiconnectalways",
    component: () => import("/app/pages/service/kakaoiconnectalways.vue")
  },
  {
    name: "service-kakaoiconnectcenter",
    path: "/service/kakaoiconnectcenter",
    component: () => import("/app/pages/service/kakaoiconnectcenter.vue")
  },
  {
    name: "service-kakaoiconnectmsg",
    path: "/service/kakaoiconnectmsg",
    component: () => import("/app/pages/service/kakaoiconnectmsg.vue")
  },
  {
    name: "service-kakaoiconnecttalk",
    path: "/service/kakaoiconnecttalk",
    component: () => import("/app/pages/service/kakaoiconnecttalk.vue")
  },
  {
    name: "service-kakaowork",
    path: "/service/kakaowork",
    component: () => import("/app/pages/service/kakaowork.vue")
  },
  {
    name: "service-sap",
    path: "/service/sap",
    component: () => import("/app/pages/service/sap.vue")
  },
  {
    name: "service-smartconstruction",
    path: "/service/smartconstruction",
    component: () => import("/app/pages/service/smartconstruction.vue")
  },
  {
    name: "service-smartminwon",
    path: "/service/smartminwon",
    component: () => import("/app/pages/service/smartminwon.vue")
  },
  {
    name: "service-uiux",
    path: "/service/uiux",
    component: () => import("/app/pages/service/uiux/index.vue")
  },
  {
    name: "service-uiux-portfolio",
    path: "/service/uiux/portfolio",
    component: () => import("/app/pages/service/uiux/portfolio.vue")
  },
  {
    name: "waytocome",
    path: "/waytocome",
    component: () => import("/app/pages/waytocome.vue")
  }
]