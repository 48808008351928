<template>
  <a
    v-if="isBlank"
    :href="props.link"
    target="_blank"
    rel="noopener noreferrer"
    >{{ props.text
    }}<span
      v-if="irText"
      class="screen_out"
      >{{ props.irText }}</span
    ><span
      v-else
      class="screen_out"
      >새창열림</span
    ></a
  >
  <NuxtLink
    v-else
    :to="props.link"
    @click="refreshCurrPage(props.link)"
    >{{ props.text }}</NuxtLink
  >
</template>
<!-- 사용시
<LinkBasic
  :text="'위치정보사업 이용약관'"
  :link="'https://policy.dktechin.com/location/dktechin'"
  :isBlank="true"
  :irText="'새창열림외 별도의 텍스트'"
/>
-->
<script setup>
const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  link: {
    type: String,
    default: '',
  },
  isBlank: {
    type: Boolean,
    default: false,
  },
  irText: {
    type: String,
    default: '',
  },
});
</script>
